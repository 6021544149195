import { initializeIcons } from "@fluentui/font-icons-mdl2";
import { Navigate, Route, Routes } from "react-router";
import { BrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "./App.scss";
import ErrorPage from "./components/ErrorPage/ErrorPage";
import Ladetrans from "./components/Ladetrans/Ladetrans";
import Login from "./components/Login/Login";
import { ApiService } from "./services/api.service";

initializeIcons(
    'https://res.cdn.office.net/files/fabric-cdn-prod_20240129.001/assets/icons/',
  );
function App() {
  const apiCalls: ApiService = ApiService.getInstance();

  const loggedIn = apiCalls.atc;

  return (
    <>
      <ToastContainer />
      <BrowserRouter>
        <Routes>
          <Route path="/error" element={<ErrorPage />} />
          <Route path="/login" element={<Login />} />
          <Route
            path="/"
            element={
              !loggedIn ? <Navigate replace to="/login" /> : <Ladetrans />
            }
          />
          <Route path="/*" element={<Ladetrans />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
